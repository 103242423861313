import { MAINTAIN_PERMISSION, DELETE_PERMISSION, EXO_TRACKING_STATUS } from "@kraftheinz/common";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", {
    attrs: {
      "title": "Tracking Status",
      "search-by": "Status ",
      "ph-search-by": "Search by Status",
      "actions-width": 120
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function(_ref) {
        var edit = _ref.edit, record = _ref.record, toggleActive = _ref.toggleActive;
        return [_vm.can(_vm.permissions.delete) ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v(" " + _vm._s(record.isInactive ? "Activate" : "Deactivate") + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "list__action-toggle-active mr-2",
          attrs: {
            "icon": record.isInactive ? "eye-invisible" : "eye",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return toggleActive(record);
            }
          }
        })], 1) : _vm._e(), _vm.can(_vm.permissions.maintain) ? _c("a-tooltip", {
          scopedSlots: _vm._u([{
            key: "title",
            fn: function() {
              return [_vm._v("Edit")];
            },
            proxy: true
          }], null, true)
        }, [_c("a-button", {
          staticClass: "mr-2",
          attrs: {
            "disabled": record.isInactive,
            "icon": "edit",
            "size": "small",
            "type": "link"
          },
          on: {
            "click": function($event) {
              return edit(record);
            }
          }
        })], 1) : _vm._e()];
      }
    }])
  }, [_c("text-field", {
    key: "Code",
    attrs: {
      "data-index": "code",
      "title": "Code",
      "width": 210,
      "sorter": true
    }
  }), _c("text-field", {
    key: "Status",
    attrs: {
      "data-index": "status",
      "title": "Status",
      "sorter": true
    }
  })], 1);
};
var staticRenderFns$1 = [];
const __vue2_script$1 = {
  name: "ListTrackingStatus",
  inject: ["can"],
  data() {
    return {
      permissions: {
        maintain: MAINTAIN_PERMISSION,
        delete: DELETE_PERMISSION
      }
    };
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, null, null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var ListTrackingStatus = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", {
    staticClass: "list-tracking-status"
  }, [_c("bread-crumb", {
    attrs: {
      "items": _vm.itemsMenu
    }
  }), _c("resource", {
    attrs: {
      "name": "export-order.manage-tracking-status",
      "api-url": _vm.apiUrl,
      "create-route": "/export-orders/tracking-status/create",
      "edit-route": "/export-orders/tracking-status/:id",
      "page": _vm.page
    }
  }, [_c("list-tracking-status")], 1), _c("router-view")], 1);
};
var staticRenderFns = [];
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: {
    ListTrackingStatus
  },
  data() {
    return {
      page: EXO_TRACKING_STATUS,
      apiUrl,
      itemsMenu: [
        {
          key: "masterfiles",
          title: "Master Files",
          path: ""
        },
        {
          key: "tracking-status",
          title: "Tracking Status",
          path: "/export-orders/tracking-status"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
